const ContactForm = () => {
    return (
        <form name="contact" method="POST" data-netlify="true" data-aos="fade-up">
            <label htmlFor="input-hidden" type="hidden" />
            <input id="input-hidden" type="hidden" name="form-name" value="contact" />
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                        <label htmlFor="contactName" className="font-weight-bold">
                            Όνομα
                        </label>
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="contactName"
                            placeholder="π.χ Γιώργος Παπαδόπουλος"
                            required
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                        <label htmlFor="contactEmail" className="font-weight-bold">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="contactEmail"
                            placeholder="example@example.com"
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="form-group mb-7 mb-md-9">
                        <label htmlFor="contactMessage" className="font-weight-bold">
                            Μήνυμα
                        </label>
                        <textarea
                            name="message"
                            className="form-control"
                            id="contactMessage"
                            rows="5"
                            placeholder="π.χ Ενδιαφέρομαι να μάθω περισσότερα για το De Jure..."
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-lg-4">
                <div className="col-auto">
                    <button type="submit">
                        Επιβεβαίωση
                    </button>
                </div>
            </div>
        </form>
    );
}

export default ContactForm;
