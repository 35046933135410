import React, { Component } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import './PDF.css';

class JournalPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
        }
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.pageBack = this.pageBack.bind(this);
        this.pageForward = this.pageForward.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    onDocumentLoadSuccess = ({ numPages }) => this.setState({ numPages });

    handleKeyPress(event) {
        if (event.keyCode === 39) {
            this.pageForward();
        } else if (event.keyCode === 37) {
            this.pageBack();
        }
    }

    pageBack() {
        let diff = window && window.innerWidth < 700 ? 1 : 2;
        this.setState({pageNumber: this.state.pageNumber - diff});
    }

    pageForward() {
        let diff = window && window.innerWidth < 700 ? 1 : 2;
        this.setState({pageNumber: this.state.pageNumber + diff});
    }

    render() {
        let pages = [this.state.pageNumber, parseInt(this.state.pageNumber + 1)];
        if (window && window.innerWidth < 700) pages = [this.state.pageNumber];

        return (
            <div className="card pdf-container shadow-lg mt-lg-4">
                <Document
                    file={this.props.pdf}
                    onLoadSuccess={ this.onDocumentLoadSuccess }
                    options={{
                        cMapUrl: 'cmaps/',
                        cMapPacked: true,
                        workerSrc: "pdf.worker.js"
                    }}
                    className="pdf-document"
                >
                    {pages.map((page) => {
                        if (page <= this.state.numPages)
                            return <Page key={ page } pageNumber={ page } className="pdf-page" />
                        else
                            return null
                    })}
                </Document>
                <div className="pdf-actions shadow-lg">
                    <button
                        type="button"
                        disabled={this.state.pageNumber <= 2}
                        onClick={ this.pageBack }
                    >
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.152 14.8281L2 7.85352L9.21755 1" stroke="#0A3087" strokeWidth="2"/>
                        </svg>
                    </button>
                    <span>Page {this.state.pageNumber || (this.state.numPages ? 1 : "--")} of {this.state.numPages || "--"}</span>
                    <button
                        type="button"
                        disabled={this.state.pageNumber >= this.state.numPages - 1}
                        onClick={ this.pageForward }
                    >
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.06553 14.8281L8.21753 7.85352L0.999984 1" stroke="#0A3087" strokeWidth="2"/>
                        </svg>
                    </button>
                </div>
            </div>
        );
    }
}

export default JournalPDF;
