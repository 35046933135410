import { Link } from 'react-router-dom';

import LogoHeader from '../../assets/LogoHeader.svg';
import './Navigation.css';

function Footer () {
    return (
        <footer className="pt-5 pb-5 bg-primary">
            <div className="container pt-3 pb-3">
                <div className="row">
                    <div className="col-12 col-lg-4 text-center mb-5 mb-lg-0">
                        <Link to="/">
                            <img src={ LogoHeader } className="img-fluid" alt="Dejure Journal Logo" />
                        </Link>
                    </div>
                    <div className="col-6 col-lg-3 offset-lg-1">
                        <ul className="list-unstyled">
                            <li className="mb-3">
                                <Link to="/journals" className="nav-link">
                                    Τεύχη
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/team" className="nav-link">
                                    Συντακτική Ομάδα
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/writer" className="nav-link">
                                    Γίνε Συντάκτης
                                </Link>
                            </li>
                            <li>
                                <Link to="/sponsor" className="nav-link">
                                    Γίνε Χορηγός
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-4">
                        <ul className="list-unstyled">
                            <li className="mb-3">
                                <Link to="/news" className="nav-link">
                                    Ενημερώσεις
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/contact" className="nav-link">
                                    Επικοινωνία
                                </Link>
                            </li>
                            <li className="mb-3">
                                <a href="https://www.elsa-greece.org/elsa-athens/" className="nav-link" target="_blank" rel="noreferrer noopener">
                                    ELSA Athens
                                </a>
                            </li>
                            <li className="ml-3">
                                <a href="https://www.facebook.com/DeJureJournalElsaAthens/" className="text-decoration-none mr-3" target="_blank" rel="noreferrer noopener">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0)">
                                            <path d="M22.675 0H1.325C0.593 0 0 0.593 0 1.325V22.676C0 23.407 0.593 24 1.325 24H12.82V14.706H9.692V11.084H12.82V8.413C12.82 5.313 14.713 3.625 17.479 3.625C18.804 3.625 19.942 3.724 20.274 3.768V7.008L18.356 7.009C16.852 7.009 16.561 7.724 16.561 8.772V11.085H20.148L19.681 14.707H16.561V24H22.677C23.407 24 24 23.407 24 22.675V1.325C24 0.593 23.407 0 22.675 0V0Z"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                                <a href="https://www.instagram.com/elsa.athens/" className="text-decoration-none mr-3" target="_blank" rel="noreferrer noopener">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0)">
                                            <path d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0V0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                                <a href="https://www.linkedin.com/company/elsaathens/" className="text-decoration-none" target="_blank" rel="noreferrer noopener">
                                    <svg width="28" height="28" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                                        <path d='M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z'/>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
