import React, { Component } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';

import './Login.css';
import LogoHeader from '../../../assets/LogoHeader_Primary.svg';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            error: false,
            email: '',
            password: '',
            viewPass: false
        };
    }

    componentDidMount() {
        document.title = "Admin Log In | De Jure";
    }

    changeEmail = (event) => {
        this.setState({email: event.target.value.trim(), error: false});
    };

    changePassword = (event) => {
        this.setState({password: event.target.value});
    };


    toggleViewPass = () => {
        this.setState({viewPass: !this.state.viewPass});
    };

    login = (event) => {
        event.preventDefault();

        axios.post(process.env.REACT_APP_URI + '/user/login', {
            user: {
                email: this.state.email,
                password: this.state.password
            }
        }).then((response) => {
            if (response.data.user) {
                localStorage.setItem('token', response.data.user.token);
                this.setState({loggedIn: true});
                window.location.reload();
            }
        }).catch(() => {
            this.setState({error: true});
        });
    };

    render() {
        if (localStorage.getItem('token') !== null || this.state.loggedIn) {
            return (<Redirect to="/admin" />);
        }

        return (
            <div className="login-register container">
                <div className="row mb-5 justify-content-center">
                    <div className="col-8">
                        <img src={ LogoHeader } className="img-fluid" alt="Login illustration" />
                    </div>
                </div>
                <div className="row justify-content-center align-items-center">
                    <div className="col-12">
                        <form className="row justify-content-center" onSubmit={ this.login }>
                            <div className="col-12 col-lg-8">
                                <div className="form-group">
                                    <input className={this.state.error ? "form-control input-registration-special-error" : "form-control input-registration-special"} type="email" placeholder="Email" value={ this.state.email } onChange={ this.changeEmail } required />
                                </div>
                            </div>
                            <div className="col-12 col-lg-8">
                                <div>
                                    <input className={this.state.error ? "form-control input-registration-special-error" : "form-control input-registration-special"} type={this.state.viewPass ? 'text' : "password"} placeholder="Password" value={ this.state.password } onChange={ this.changePassword } required />
                                    <div className="password-trigger cursor-pointer" onClick={this.toggleViewPass}>{this.state.viewPass ? "Hide" : "Show"}</div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-8 mt-4">
                                <div className="form-group text-center">
                                    { this.state.error && <div className="alert alert-danger">Email or password is incorrect.</div> }
                                    <button type="submit">Log In</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
