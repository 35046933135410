import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';

import './JournalUpload.css';

const JournalUpload = () => {
    let history = useHistory();
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [isPrivate, setIsPrivate] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleImage = (event) => {
        if (event.target.files.length) {
            setImage({
                preview: URL.createObjectURL(event.target.files[0]),
                raw: event.target.files[0]
            });
        }
    }

    const onDropImage = (images) => {
        if (images.length > 0) {
            let image = images[0];
            Object.assign(image, { preview: URL.createObjectURL(image) });
            setImage(image);
        }
    };

    const onDropFile = (files) => {
        setFile(null);
        setFileUrl(null)
        if (files.length > 0) {
            let file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setFile(file);
                setFileUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const bytesToSize = (bytes) => {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };


    const handleUpload = async event => {
        event.preventDefault();
        if (image === null || title === null || title.length === 0 || file === null) {
            setLoading(false);
            return;
        }
        let formData = new FormData();
        formData.append('image', image, image.name);
        formData.append('file', file, file.name);
        formData.append('title', title);
        formData.append('isPrivate', isPrivate);
        try {
            await axios.post(process.env.REACT_APP_URI + '/journals/new-journal', formData);
            setLoading(false);
            history.push("/admin/journals");
        } catch (err) {
            setLoading(false);
            console.log({ error: err });
        }
    }

    return (
        <form className="form row">
            <div className="col-12 mb-4 input-item">
                <label htmlFor="title" className="font-weight-bold">Τίτλος</label>
                <input
                    name="title"
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
            </div>
            <div className="col-12 mb-4 input-item">
                <label className="font-weight-bold">Φωτογραφία</label>
                { image ?
                    <>
                        <div className="input-image-container">
                            <img src={image.preview} alt="Image preview" className="img-fluid" />
                        </div>
                        <button
                            className="mt-3"
                            onClick={() => setImage(null)}
                        >
                            Αλλαγή
                        </button>
                    </>
                    :
                    <Dropzone onDrop={ onDropImage } accept="image/*" maxFiles={ 1 }>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return (
                                <div
                                    {...getRootProps()}
                                    className={classNames('dropzone dz-clickable text-center', {'dropzone--isActive text-center': isDragActive})}
                                >
                                    <input {...getInputProps()} />
                                    { image === null ? isDragActive ?
                                        <div className="dz-default dz-message"><span>Drop files here...</span></div> :
                                        <div className="dz-default dz-message"><span>Drop an image here, or click to select files to upload.</span></div>
                                        :
                                        <div className="dz-preview dz-file-preview dz-complete text-center">
                                            <div className="dz-image">
                                                <img data-dz-thumbnail="" />
                                            </div>
                                            <div className="dz-details">
                                                <div className="dz-size">
                                                    <span data-dz-size="">{ bytesToSize(image.size) }</span>
                                                </div>
                                                <div className="dz-filename">
                                                    <span data-dz-name="">{ image.name }</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        }}
                    </Dropzone>
                }
            </div>
            <div className="col-12 mb-4 input-item">
                <label className="font-weight-bold">Αρχείο Τεύχους</label>
                <Dropzone onDrop={ onDropFile } accept=".pdf" maxFiles={ 1 }>
                    {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                            <div
                                {...getRootProps()}
                                className={classNames('dropzone dz-clickable text-center', {'dropzone--isActive text-center': isDragActive})}
                            >
                                <input {...getInputProps()} />
                                { file === null ? isDragActive ?
                                    <div className="dz-default dz-message"><span>Drop files here...</span></div> :
                                    <div className="dz-default dz-message"><span>Drop a PDF here, or click to select files to upload.</span></div>
                                    :
                                    <div className="dz-preview dz-file-preview dz-complete text-center">
                                        <div className="dz-image">
                                            <img data-dz-thumbnail="" />
                                        </div>
                                        <div className="dz-details">
                                            <div className="dz-size">
                                                <span data-dz-size="">{ bytesToSize(file.size) }</span>
                                            </div>
                                            <div className="dz-filename">
                                                <span data-dz-name="">{ file.name }</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    }}
                </Dropzone>
            </div>
            <div className="col-12 mb-4 input-item">
                <label className="font-weight-bold">Πρόσβαση</label>
                <div className="access-input-group">
                    <div className={`access-input-button ${isPrivate ? 'active' : ''} mr-4`} onClick={() => setIsPrivate(true)}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.25 16.25V8.82812C26.25 7.17052 25.5915 5.58081 24.4194 4.40871C23.2473 3.23661 21.6576 2.57813 20 2.57812C18.3424 2.57813 16.7527 3.23661 15.5806 4.40871C14.4085 5.58081 13.75 7.17052 13.75 8.82812V16.25" stroke="#0A3087" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M28.75 16.25H11.25C9.17893 16.25 7.5 17.9289 7.5 20V33.75C7.5 35.8211 9.17893 37.5 11.25 37.5H28.75C30.8211 37.5 32.5 35.8211 32.5 33.75V20C32.5 17.9289 30.8211 16.25 28.75 16.25Z" stroke="#0A3087" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <div className={`access-input-button ${!isPrivate ? 'active' : ''} mr-4`} onClick={() => setIsPrivate(false)}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.25 8.75C26.25 7.0924 25.5915 5.50269 24.4194 4.33058C23.2473 3.15848 21.6576 2.5 20 2.5C18.3424 2.5 16.7527 3.15848 15.5806 4.33058C14.4085 5.50269 13.75 7.0924 13.75 8.75V16.25" stroke="#0A3087" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M28.75 16.25H11.25C9.17893 16.25 7.5 17.9289 7.5 20V33.75C7.5 35.8211 9.17893 37.5 11.25 37.5H28.75C30.8211 37.5 32.5 35.8211 32.5 33.75V20C32.5 17.9289 30.8211 16.25 28.75 16.25Z" stroke="#0A3087" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <span className="font-weight-bold">{isPrivate ? "Ιδιωτική" : "Δημόσια"}</span>
                </div>
            </div>
            <div className="col-12">
                <button onClick={ (event) => {setLoading(true); handleUpload(event); }}>
                    { loading ? <div className="loader loader-white" /> : 'Αποθήκευση' }
                </button>
            </div>
        </form>
    );
}

export default JournalUpload;
