import { useState } from 'react';
import { Link } from 'react-router-dom';
import Logotype from '../../../assets/Logotype.svg';
import '../../Navigation/Navigation.css';

function Navigation(props) {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    function logout() {
        localStorage.removeItem('token');
    }

    return (
        <nav id="navbar" className="navbar bg-primary shadow-soft-primary navbar-expand-lg">
            <div className="container-fluid">
                <div className="navbar-header">
                    <Link className="navbar-brand" to="/">
                        <img src={ Logotype } className="img-fluid" alt="Brand" />
                    </Link>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#colNav" aria-controls="colNav" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                    <svg className="text-secondary" xmlns='http://www.w3.org/2000/svg' width="50px" height="50px" viewBox='0 0 512 512'>
                        <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='50' d='M96 75h320M96 200h320M96 325h320'/>
                    </svg>
                </button>
                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="colNav">
                    <ul className={`navbar-nav ${isNavCollapsed ? 'ml-auto' : 'text-right'}`}>
                        <li className={`nav-item ${props.active === 'journals' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/admin/journals">Τεύχη</Link>
                        </li>
                        <li className={`nav-item ${props.active === 'news' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/admin/news">Ενημερώσεις</Link>
                        </li>
                        <li className={`nav-item ${props.active === 'team' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/admin/team">Συντακτική Ομάδα</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/" onClick={ logout } className="nav-link">Αποσύνδεση</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;
