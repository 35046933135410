import { Helmet } from 'react-helmet'
import Header from '../Header/Header';
import Main from './Main';

function Home() {
    return (
        <div className="home">
            <Helmet>
                <title>De Jure Journal | Home</title>
                <meta name="description" content="Το De Jure είναι το Nομικό Περιοδικό της ELSA Athens." />
            </Helmet>
            <Header />
            <Main />
        </div>
    );
}

export default Home;
