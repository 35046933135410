import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'reactstrap';

const AccessCodeModal = ({ isOpen, setShowAccessCodeModal, setAccessCode, errorMessage, setErrorMessage }) => {
    const [modalAccessCode, setModalAccessCode] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (modalAccessCode.length === 0) return;
        setAccessCode(modalAccessCode);
    }
    return (
        <Modal
          isOpen={ isOpen }
          className="modal-md"
          animation="true"
          centered={true}
          // toggle={ () => setShowAccessCodeModal(false) }
        >
            <div className="container p-5">
                <div className="row align-items-right">
                    <Link className="close-icon" to="/journals">
                        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="1.06078" y1="1.93934" x2="22.274" y2="23.1525" stroke="#B0E0C3" strokeWidth="3"/>
                            <line x1="22.2739" y1="3.06066" x2="1.06071" y2="24.2739" stroke="#B0E0C3" strokeWidth="3"/>
                        </svg>
                    </Link>
                </div>
                <form className="row">
                    <div className="col-12 input-item">
                        <h3>
                            <label htmlFor="accessCode" className="font-weight-bold">
                                Κωδικός
                            </label>
                        </h3>
                        <input
                            type="password"
                            name="accessCode"
                            value={modalAccessCode}
                            onChange={(event) => {
                                setModalAccessCode(event.target.value);
                                setErrorMessage('');
                            }}
                            required
                        />
                        { errorMessage !== '' && <small className="text-danger">{errorMessage}</small> }
                    </div>
                    <div className="col-12 mt-3">
                        <button className="float-right" onClick={handleSubmit}>Επιβεβαίωση</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}

export default AccessCodeModal;
