import Navigation from '../Navigation/Navigation';
import ArticleList from './ArticleList';

const News = () => {
    return (
        <div className="news">
            <Navigation active="news" />
            <div className="container mt-5">
                <h1 className="text-primary bold">Ενημερώσεις</h1>
                <ArticleList />
            </div>
        </div>
    );
}

export default News;
