import { useState } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';

import Navigation from '../Navigation/Navigation';
import JournalList from '../../Journals/JournalList';
import AccessCodeModal from './AccessCodeModal';
import './Journals.css';

const bearer = axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
const Journals = () => {
    const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);

    document.title = "Admin Journals | De Jure";

    if (localStorage.getItem('token') === null) {
        return (<Redirect to="/admin/login"/>);
    }


    return (
        <div className="admin-journals">
            <Navigation active="journals" />
            <AccessCodeModal
                isOpen={ showAccessCodeModal }
                setShowAccessCodeModal={ setShowAccessCodeModal }
            />
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <h1 className="text-primary bold">Τεύχη</h1>
                    </div>
                    <div className="col-12 col-lg-6 float-right">
                        <button onClick={() => setShowAccessCodeModal(true)}>Κωδικός</button>
                    </div>
                </div>
                <JournalList />
            </div>
        </div>
    );
}

export default Journals;
