import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import Navigation from '../Navigation/Navigation';
import TeamMd from './TeamMd';

const bearer = axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
const Team = () => {
    document.title = "Team | De Jure";

    return (
        <div className="admin-news">
            <Helmet>
                <title>De Jure Journal | Team</title>
                <meta name="description" content="Το περιοδικό φιλοξενεί δημοσιεύσεις και μελέτες διακεκριμένων καθηγητών και φοιτητών της Νομικής Σχολής Αθηνών καθώς και πανεπιστημίων του εξωτερικού σε θέματα δημοσίου, ιδιωτικού, ποινικού, διεθνούς και ευρωπαϊκού δικαίου." />
            </Helmet>
            <Navigation active="team" />
            <div className="container p-5 p-lg-0 mt-5 mb-5">
                <div className="row">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        <h1 className="text-primary bold">Συντακτική Ομάδα</h1>
                    </div>
                </div>
                <TeamMd />
            </div>
        </div>
    );
}

export default Team;
