import Navigation from '../Navigation/Navigation';
import ContactForm from './ContactForm';

const Contact = () => {
    document.title = "Επικοινωνία | De Jure";
    return (
        <div className="contact">
            <Navigation active="contact" />
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-primary bold">Επικοινωνία</h1>
                        <p className="mt-lg-4">
                            Θέλετε να μάθετε περισσότερα για το De Jure ή την ELSA Athens; Μην διστάσετε να επικοινωνήσετε μαζί μας! Απλά συμπληρώστε την παρακάτω φόρμα και θα επικοινωνήσουμε μαζί σας σύντομα.
                        </p>
                    </div>
                </div>
                <hr />
                <div className="row align-items-center mt-lg-5 mb-lg-5">
                    <div className="col-12 col-lg-8">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
