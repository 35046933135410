import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import ArticleCard from "./ArticleCard";

const ArticleList = () => {
    document.title = "News | De Jure";

    let history = useHistory();
    const [articles, setArticles] = useState([1, 2, 3, 4, 5]);
    const { slug } = useParams();

    useEffect(() => {
        getArticles(slug);
    }, [slug]);

    const getArticles = async (slug) => {
        try {
            const res = await axios.get(process.env.REACT_APP_URI + `/articles/`);
            setArticles(res.data);
        } catch (err) {
            console.log({ error: err });
        }
    };

    return (
        <SkeletonTheme color="rgba(10, 48, 135, .25)" highlightColor="rgba(10, 48, 135, .25)">
            <div className="row mt-5 mb-5">
                { articles.map((article, index) => {
                        return (
                            <ArticleCard
                                image={article.image}
                                title={article.title}
                                description={article.description}
                                slug={article.slug}
                                createdAt={article.createdAt}
                                size={
                                    index === 0 ? 'lg'
                                    : index < 3 ? 'md'
                                    : 'sm'
                                }
                            />
                        );
                    })
                }
            </div>
        </SkeletonTheme>
    );
};

export default ArticleList;
