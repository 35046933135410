import { Link } from "react-router-dom";

import Navigation from '../Navigation/Navigation';
import EditTeamForm from './EditTeamForm';

const NewArticle = () => {
    if (localStorage.getItem('token') === null) {
        return (<Redirect to="/admin/login"/>);
    }
    return (
        <div>
            <Navigation active="team" />
            <div className="container mb-5">
                <div className="row">
                    <div className="col-12 mt-4 mb-4">
                        <Link to="/admin/team" className="back-link">
                            <svg className="mr-2" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.152 14.8281L2 7.85352L9.21755 1" stroke="#0A3087" stroke-width="2"/>
                            </svg>
                            <span className="back-link-text">Επιστροφή</span>
                        </Link>
                    </div>
                </div>
                <div className="row mt-lg-4">
                    <div className="col-12">
                        <h1 className="text-primary bold">Συντακτική Ομάδα</h1>
                        <hr />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 col-lg-8">
                        <EditTeamForm />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewArticle;
