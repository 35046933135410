import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

import Home from './components/Home/Home';
import Journals from './components/Journals/Journals';
import Journal from './components/Journals/Journal';
import Writer from './components/Writer/Writer';
import Sponsor from './components/Sponsor/Sponsor';
import Team from './components/Team/Team';
import News from './components/News/News';
import Article from './components/News/Article';
import Contact from './components/Contact/Contact';
import FormSubmit from './components/Helpers/FormSubmit';

import AdminJournals from './components/Admin/Journals/Journals';
import AdminNewJournal from './components/Admin/Journals/NewJournal';
import AdminTeam from './components/Admin/Team/Team';
import AdminEditTeam from './components/Admin/Team/EditTeam';
import AdminNews from './components/Admin/Articles/Articles';
import AdminNewArticle from './components/Admin/Articles/NewArticle';
import AdminLogin from './components/Admin/Login/Login';
import Footer from './components/Navigation/Footer';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={ Home } />
                <Route exact path="/journals" component={ Journals } />
                <Route path="/journal/:id" component={ Journal } />
                <Route exact path="/team" component={ Team } />
                <Route exact path="/news" component={ News } />
                <Route path="/news/:slug" component={ Article }/>
                <Route exact path="/writer" component={ Writer } />
                <Route exact path="/sponsor" component={ Sponsor } />
                <Route exact path="/contact" component={ Contact } />
                <Route exact path="/thank-you" component={ FormSubmit } />

                <Redirect exact path="/admin/" to="/admin/journals" />
                <Route exact path="/admin/journals" component={ AdminJournals } />
                <Route exact path="/admin/journals/new" component={ AdminNewJournal } />
                <Route exact path="/admin/team" component={ AdminTeam } />
                <Route exact path="/admin/team/edit" component={ AdminEditTeam } />
                <Route exact path="/admin/news" component={ AdminNews } />
                <Route exact path="/admin/news/new" component={ AdminNewArticle } />
                <Route exact path="/admin/news/edit/:slug" component={ AdminNewArticle } />
                <Route path="/admin/news/:slug" component={ Article }/>
                <Route exact path="/admin/login" component={ AdminLogin } />
            </Switch>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
