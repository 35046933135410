import { Helmet } from 'react-helmet';
import Navigation from '../Navigation/Navigation';
import WriterForm from './WriterForm';

const Writer = () => {
    document.title = "Γίνε Συντάκτης | De Jure";
    return (
        <div className="writer">
            <Helmet>
                <title>De Jure Journal | Γίνε Συντάκτης</title>
                <meta name="description" content="Το De Jure Journal είναι το επιστημονικό περιοδικό της ELSA Athens και δίνει το βήμα σε κάθε νέα και νέο νομικό που θέλει να εμβαθύνει στη νομική επιστήμη να εξελίξει και να δώσει σάρκα και οστά στις δικές του αναζητήσεις γύρω από το δίκαιο." />
            </Helmet>
            <Navigation active="writer" />
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-primary bold">Γίνε Συντάκτης</h1>
                        <p className="mt-lg-4">
                            Το De Jure δίνει βήμα σε κάθε νέα και νέο νομικό που θέλει να εμβαθύνει στη νομική επιστήμη. Αν λοιπόν αγαπάς έναν συγκεκριμένο κλάδο του δικαίου και επιθυμείς να ασχοληθείς σε βάθος με ένα σχετικό ζήτημα, με στόχο, ύστερα από την απαιτούμενη έρευνα, να δημοσιεύσεις τα πορίσματα και τις απόψεις σου, δεν έχεις παρά να εκδηλώσεις το ενδιαφέρον σου συμπληρώνοντας την παρακάτω φόρμα.
                        </p>
                        <p>
                            Αναλυτικές οδηγίες για την διαδικασία αρθρογράφησης στο De Jure θα βρεις στον <a
                                href="https://s3.eu-central-1.amazonaws.com/public-files.dejurejournal/%CE%9F%CE%B4%CE%B7%CE%B3%CE%BF%CC%81%CF%82+%CE%A3%CF%85%CE%B3%CE%B3%CF%81%CE%B1%CF%86%CE%B7%CC%81%CF%82.pdf"
                                target="_blank"
                                referrerPolicy="no-referrer"
                                rel="noreferrer"
                                className="text-primary"
                            >
                                Οδηγό Συγγραφής
                                <svg className="ml-2" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width="20px" height="20px">
                                    <path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32'/>
                                    <path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448'/>
                                </svg>
                            </a>
                        </p>
                    </div>
                </div>
                <hr />
                <div className="row align-items-center mt-lg-5 mb-lg-5">
                    <div className="col-12 col-lg-8">
                        <WriterForm />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Writer;
