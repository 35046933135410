import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';

const WriterForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('Προπτυχιακός/η φοιτητής/τρια');
    const [semester, setSemester] = useState('α');
    const [topic, setTopic] = useState('');
    const [summary, setSummary] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const onDropSummary = useCallback(acceptedFiles => {
        setSummary(acceptedFiles[0]);
        setErrorMessage('');
    }, [])

    const bytesToSize = (bytes) => {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };


    const encode = (data) => {
        const formData = new FormData();
            Object.keys(data).forEach((k)=>{
              formData.append(k,data[k])
            });
        return formData
    }

    const handleSubmit = e => {
        if (!firstName
            || firstName.length === 0
            || !lastName
            || lastName.length === 0
            || !email
            || email.length === 0
            || !role
            || role.length === 0
            || !topic
            || topic.length === 0
            || !summary
            || summary === null
        ) {
            setErrorMessage('Παρακαλώ συμπληρώστε όλα τα στοιχεία.');
        } else {
            setLoading(true);
            const formSemester = role === "Προπτυχιακός/η φοιτητής/τρια" ? semester : null;
            const data = {
                "form-name": "writer",
                firstName: firstName,
                lastName: lastName,
                email: email,
                role: role,
                semester: formSemester,
                topic: topic,
                summary
            }
            axios.post("https://dejurejournal.netlify.app/writer",
                encode(data),
                {headers: { "Content-Type": "application/x-www-form-urlencoded" }}
            ).then(() => {
                setLoading(false);
                return (<Redirect to="/thank-you" />);
            })
            .catch(error => alert(error));
        }
        e.preventDefault();
    };

    return (
        <form onSubmit={handleSubmit} name="writer" data-netlify={+true} method="POST">
        {/*<form onSubmit={handleSubmit} name="writer" method="POST" data-netlify="true" data-aos="fade-up">*/}
            <label htmlFor="input-hidden" type="hidden" />
            <input id="input-hidden" type="hidden" name="form-name" value="writer" />
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-5">
                        <label htmlFor="contacFirstName" className="font-weight-bold">
                            Όνομα
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            id="firstName"
                            placeholder="π.χ Γιώργος"
                            value={firstName}
                            onChange={(event) => { setFirstName(event.target.value); setErrorMessage(''); }}
                            required
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-5">
                        <label htmlFor="contactLastName" className="font-weight-bold">
                            Επίθετο
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            id="lastName"
                            placeholder="π.χ Παπαδόπουλος"
                            value={lastName}
                            onChange={(event) => { setLastName(event.target.value); setErrorMessage(''); }}
                            required
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-5">
                        <label htmlFor="contactEmail" className="font-weight-bold">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="contactEmail"
                            placeholder="example@example.com"
                            value={email}
                            onChange={(event) => { setEmail(event.target.value); setErrorMessage(''); }}
                            required
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-5">
                        <label htmlFor="contactRole" className="font-weight-bold">
                            Ιδιότητα
                        </label><br />
                        <select
                            name="role"
                            value={role}
                            onChange={(event) => { setRole(event.target.value); setErrorMessage(''); }}
                            required
                        >
                            <option value="Προπτυχιακός/η φοιτητής/τρια">Προπτυχιακός/η φοιτητής/τρια</option>
                            <option value="Μεταπτυχιακός φοιτητής/τρια">Μεταπτυχιακός φοιτητής/τρια</option>
                            <option value="Υποψ. διδάκτορας">Υποψ. διδάκτορας</option>
                            <option value="Καθηγητής/τρια">Καθηγητής/τρια</option>
                            <option value="Δικηγόρος">Δικηγόρος</option>
                            <option value="Δικαστής">Δικαστής</option>
                            <option value="Άλλο">Άλλο</option>
                        </select>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-5">
                        <label htmlFor="contactRole" className="font-weight-bold">
                            Δήλωση Θέματος
                        </label><br />
                        <input
                            type="text"
                            id="topic"
                            name="topic"
                            value={topic}
                            onChange={(event) => { setTopic(event.target.value); setErrorMessage(''); }}
                            required
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    { role === "Προπτυχιακός/η φοιτητής/τρια"
                        && <div className="form-group mb-5">
                            <label htmlFor="contactRole" className="font-weight-bold">
                                Εξάμηνο
                            </label><br />
                            <select
                                name="semester"
                                value={semester}
                                onChange={(event) => { setSemester(event.target.value); setErrorMessage(''); }}
                                required
                            >
                                <option value="α">{"α\'"}</option>
                                <option value="β">{"β\'"}</option>
                                <option value="γ">{"γ\'"}</option>
                                <option value="δ">{"δ\'"}</option>
                                <option value="ε">{"ε\'"}</option>
                                <option value="ζ">{"ζ\'"}</option>
                                <option value="η">{"η\'"}</option>
                                <option value="θ">{"θ\'"}</option>
                            </select>
                        </div> }
                </div>
                <div className="col-12">
                    <div className="form-group mb-5">
                        <label htmlFor="summary" className="font-weight-bold">Πλάνο Έρευνας: </label>
                        <Dropzone onDrop={ onDropSummary } accept=".pdf, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" maxFiles={ 1 }>
                            {({getRootProps, getInputProps, isDragActive}) => {
                                return (
                                    <div
                                        {...getRootProps()}
                                        className={classNames('dropzone dz-clickable text-center', {'dropzone--isActive text-center': isDragActive})}
                                    >
                                        <input {...getInputProps()} />
                                        { summary === null ? isDragActive ?
                                            <div className="dz-default dz-message"><span>Drop files here...</span></div> :
                                            <div className="dz-default dz-message"><span>Drop your file here, or click to select file to upload.</span></div>
                                            :
                                            <div className="dz-preview dz-file-preview dz-complete text-center">
                                                <div className="dz-image">
                                                    <img data-dz-thumbnail="" />
                                                </div>
                                                <div className="dz-details">
                                                    <div className="dz-size">
                                                        <span data-dz-size="">{ bytesToSize(summary.size) }</span>
                                                    </div>
                                                    <div className="dz-filename">
                                                        <span data-dz-name="">{ summary.name }</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            }}
                        </Dropzone>
                    </div>
                </div>
                { errorMessage.length > 0 &&
                    <div className="col-12 mb-5">
                        <span className="alert alert-danger">{errorMessage}</span>
                    </div>
                }

                <div className="col-auto">
                    <button type="submit">
                        { loading ? <div className="loader loader-white" /> : 'Επιβεβαίωση' }
                    </button>
                </div>
            </div>
        </form>
    );
}

export default WriterForm;
