import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import JournalCard from './JournalCard';
import { SkeletonTheme } from "react-loading-skeleton";

const JournalList = () => {
    const [journals, setJournals] = useState([1, 2, 3, 4]);

    useEffect(() => {
        document.title = "Journals | De Jure";
        loadJournals();
    }, []);

    const loadJournals = async () => {
        const res = await axios.get(process.env.REACT_APP_URI + '/journals');
        setJournals(res.data.journals);
    }

    const addJournalCard = () => {
        if (localStorage.getItem('token') !== null) {
            return (
                <div className="col-12 col-lg-4 mt-5">
                    <Link to="/admin/journals/new">
                        <div className="card p-5 add-journal-card bg-secondary">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                                <path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='10' d='M256 112v288M400 256H112'/>
                            </svg>
                        </div>
                        <h3 className="journal-card-text">Νέο Τεύχος</h3>
                    </Link>
                </div>
            );
        }
    };

    return (
        <SkeletonTheme
            color="rgba(10, 48, 135, .25)"
            highlightColor="rgba(10, 48, 135, .25)"
        >
            <div className="row mb-5">
                { addJournalCard() }
                { journals.map((journal, index) => {
                    return (
                        <JournalCard
                            key={ index }
                            journal={ journal }
                        />
                    );
                })}
            </div>
        </SkeletonTheme>
    );
}

export default JournalList;
