import Navigation from '../Navigation/Navigation';
import Library from '../../assets/Library.jpg';
import Triangle1 from '../../assets/Triangle1.svg';
import './Main.css';
function Main() {
    return (
        <div className="main-section overflow-xl-hidden">
            <Navigation />
            <section className="pt-xl-5 bg-white">
                <div className="container">
                    <div className="row align-content-center pt-xl-5">
                        <div className="col-12 col-lg-7 pb-5 pt-5 pt-xl-0 pt-align-0 order-2 order-align-1 text-container">
                            <h1 className="text-primary bold">De Jure</h1>
                            <p className="mt-xl-4">
                                Το De Jure είναι το Nομικό Περιοδικό της ELSA Athens. Εκδίδεται τρεις φορές το χρόνο. Η έκδοση του περιοδικού εντάσσεται στις Ακαδημαϊκές Δραστηριότητες της ELSA Athens και αποτελεί το πρόσωπό της στον ακαδημαϊκό κόσμο.
                            </p>
                            <p>
                                Οι πρώτες του σελίδες είδαν το φως το Δεκέμβρη του 2008, οπότε ξεκίνησε το τολμηρό και δημιουργικό εγχείρημα της σύνθεσης και έκδοσης του περιοδικού.
                            </p>
                            <p>
                                Το περιοδικό φιλοξενεί άρθρα και μελέτες διακεκριμένων καθηγητών και φοιτητών της Νομικής Σχολής Αθηνών καθώς και πανεπιστημίων του εξωτερικού σε θέματα δημοσίου, ιδιωτικού, ποινικού, διεθνούς και ευρωπαϊκού δικαίου. Επιπροσθέτως, στο περιοδικό φιλοξενούνται συνεντεύξεις διακεκριμένων προσώπων από το χώρο των νομικών επιστημών. Το De Jure αριθμεί ήδη 25 τεύχη, τα οποία είναι διαθέσιμα τόσο σε έντυπη όσο και σε ηλεκτρονική μορφή.
                            </p>
                            <p>
                                Για τα ταμειακώς ενήμερα μέλη η έντυπη έκδοση του περιοδικού διατίθεται στα 5 ευρώ και η ηλεκτρονική δωρεάν. Για τους υπόλοιπους η έντυπη έκδοση στα 6 ευρώ και η ηλεκτρονική στα 2 ευρώ. Για παραγγελίες επικοινωνήστε στο <a href="mailto:publisher.dejure@gr.elsa.org" className="email">publisher.dejure@gr.elsa.org</a>
                            </p>
                        </div>
                        <div className="col-12 col-lg-5 order-1 order-lg-2 img-container">
                            <div className="d-none d-lg-block img-box right">
                                <img src={ Library } alt="Library" className="library"/>
                                <img src={ Triangle1 } alt="Triangle" className="shape" />
                            </div>
                            <div className="d-block d-lg-none img-cover">
                                <img src={ Library } alt="Library" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Main;
