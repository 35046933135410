import { HashLink as Link } from 'react-router-hash-link';
import DeJureFadedHeader from '../../assets/DeJureFadedHeader.svg';
import Scale from '../../assets/Scale.svg';
import LogoHeader from '../../assets/LogoHeader.svg';

import './Header.css';

function Header() {
    return (
        <div className="full-screen main">
            <img src={ DeJureFadedHeader } className="DeJureFadedHeader" />
            <div className="DeJureScaleHeader">
                <img src={ Scale } alt="Scale of justice"/>
            </div>
            <div className="LogoHeader">
                <img src={ LogoHeader } alt="Header illustration" />
            </div>
            <Link className="ArrowDown" to="/#navbar">
                <svg width="104" height="42" viewBox="0 0 104 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2L52.7871 39.0247L102 2" strokeWidth="4" strokeLinejoin="round"/>
                </svg>
            </Link>
        </div>
    );
}

export default Header;
