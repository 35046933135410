import { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'reactstrap';

const AccessCodeModal = ({ isOpen, setShowAccessCodeModal }) => {
    const [accessCode, setAccessCode] = useState('');
    const [id, setId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (accessCode.length === 0) return;
        if (accessCode.length < 8) setErrorMessage('Ο κωδικός πρέπει να είναι τουλάχιστον 8 χαρακτήρες');
        try {
            const response = await axios.put(process.env.REACT_APP_URI + '/access-codes/edit', {
                id: id,
                accessCode: accessCode,
            });
            setSuccessMessage('Ο κωδικός άλλαξε με επιτυχία');
            loadAccessCode();
        } catch (error) {
            console.log(error);
        }

    }

    const loadAccessCode = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_URI + '/access-codes/');
            setAccessCode(response.data.accessCodes[0].code);
            setId(response.data.accessCodes[0]._id);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadAccessCode();
    }, []);

    if (localStorage.getItem('token') === null) {
        return (<Redirect to="/admin/login"/>);
    }

    return (
        <Modal
          isOpen={ isOpen }
          contentLabel="Access Code"
          className="modal-md"
          animation="true"
          centered={true}
          toggle={ () => setShowAccessCodeModal(false) }
        >
            <div className="container p-5">
                <div className="row align-items-right">
                    <div className="close-icon" onClick={ () => setShowAccessCodeModal(false) }>
                        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="1.06078" y1="1.93934" x2="22.274" y2="23.1525" stroke="#B0E0C3" strokeWidth="3"/>
                            <line x1="22.2739" y1="3.06066" x2="1.06071" y2="24.2739" stroke="#B0E0C3" strokeWidth="3"/>
                        </svg>
                    </div>
                </div>
                <form className="row">
                    <div className="col-12 input-item">
                        <h3>
                            <label htmlFor="accessCode" className="font-weight-bold">
                                Κωδικός
                            </label>
                        </h3>
                        <input
                            type="text"
                            name="accessCode"
                            value={accessCode}
                            onChange={(event) => {
                                setAccessCode(event.target.value);
                                setErrorMessage('');
                                setSuccessMessage('');
                            }}
                            required
                        />
                        { errorMessage !== '' && <small className="text-danger">{ errorMessage }</small> }
                        { successMessage !== '' && <small className="text-success">{ successMessage }</small> }
                    </div>
                    <div className="col-12 mt-3">
                        <button className="float-right" onClick={handleSubmit}>Επιβεβαίωση</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}

export default AccessCodeModal;
