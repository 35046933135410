import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

const NewArticleForm = () => {
    let history = useHistory();
    let { slug } = useParams();
    const [editing, setEditing] = useState(false);
    const [id, setId] = useState("");
    const [article, setArticle] = useState({
        title: "",
        description: "",
        markdown: "",
    });
    const [image, setImage] = useState(null);


    useEffect(() => {
        if (slug) {
            getArticleBySlug(slug);
            setEditing(true);
        }
    }, [slug]);

    const onDropImage = (images) => {
        if (images.length > 0) {
            let image = images[0];
            Object.assign(image, { preview: URL.createObjectURL(image) });
            setImage(image);
        }
    };

    const bytesToSize = (bytes) => {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setArticle({ ...article, [name]: value });
    };

    const getArticleBySlug = async (slug) => {
        try {
            const res = await axios.get(process.env.REACT_APP_URI + `/articles/${slug}`);
                setArticle(res.data);
                setId(res.data._id);
                setImage({name: "Existing Image", preview: res.data.image});
        } catch (err) {
            console.log({ error: err });
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        let formData = new FormData();
        if (image && image.name !== "Existing Image") {
            formData.append('image', image, image.name);
        } else {
            formData.append('image', image !== null ? image.preview : '');
        }
        formData.append('title', article.title);
        formData.append('description', article.description);
        formData.append('markdown', article.markdown);
        formData.append('newImage', image && image !== null && image.name !== "Existing Image");
        if (editing) {
            try {
                // axios.defaults.headers.common["Authorization"] = sessionStorage.token;
                await axios.put(process.env.REACT_APP_URI + `/articles/update/${id}`, formData);
                    history.push(`/admin/news/${slug}`);
                } catch (err) {
                    console.log({ error: err });
                }
        } else {
            try {
                // axios.defaults.headers.common["Authorization"] = sessionStorage.token;
                await axios.post(process.env.REACT_APP_URI + "/articles/new", formData);
                history.push("/admin/news");
            } catch (err) {
                console.log({ error: err });
            }
        }
    };

    return (
        <form className="row new-article">
            <div className="col-12 mb-4 input-item">
                <label htmlFor="title" className="font-weight-bold">Τίτλος</label>
                <input
                    type="text"
                    required
                    placeholder="Τίτλος"
                    name="title"
                    value={article.title}
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-12 mb-4 input-item">
                <label className="font-weight-bold">Φωτογραφία</label>
                { image ?
                    <>
                        <div className="input-image-container">
                            <img src={image.preview} alt="Image preview" className="img-fluid" />
                        </div>
                        <button
                            className="mt-3"
                            onClick={() => setImage(null)}
                        >
                            Αλλαγή
                        </button>
                    </>
                    :
                    <Dropzone onDrop={ onDropImage } accept="image/*" maxFiles={ 1 }>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return (
                                <div
                                    {...getRootProps()}
                                    className={classNames('dropzone dz-clickable text-center', {'dropzone--isActive text-center': isDragActive})}
                                >
                                    <input {...getInputProps()} />
                                    { image === null ? isDragActive ?
                                        <div className="dz-default dz-message"><span>Drop files here...</span></div> :
                                        <div className="dz-default dz-message"><span>Drop an image here, or click to select files to upload.</span></div>
                                        :
                                        <div className="dz-preview dz-file-preview dz-complete text-center">
                                            <div className="dz-image">
                                                <img data-dz-thumbnail="" />
                                            </div>
                                            <div className="dz-details">
                                                <div className="dz-size">
                                                    <span data-dz-size="">{ bytesToSize(image.size) }</span>
                                                </div>
                                                <div className="dz-filename">
                                                    <span data-dz-name="">{ image.name }</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        }}
                    </Dropzone>
                }
            </div>
            <div className="col-12 mb-4 input-item">
                <label htmlFor="description" className="font-weight-bold">Περιγραφή</label>
                <input
                    type="text"
                    required
                    placeholder="Περιγραφή"
                    name="description"
                    value={article.description}
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-12 mb-5 input-item markdown">
                <label htmlFor="markdown" className="font-weight-bold">Κείμενο</label>
                <SimpleMDE
                    value={article.markdown}
                    onChange={(event) => {handleInputChange({ target: {name: "markdown", value: event}})}}
                />
            </div>
            <div className="col-12">
                <button onClick={onSubmit}>Αποθήκευση</button>
            </div>
        </form>
    );
};

export default NewArticleForm;
