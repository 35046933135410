import React, { useState } from 'react';
const SponsorForm = () => {
    const [sponsorType, setSponsorType] = useState('Νομικό');

    return (
        <form name="sponsor" data-netlify={+true} method="POST">
            <label htmlFor="input-hidden" type="hidden" />
            <input id="input-hidden" type="hidden" name="form-name" value="sponsor" />
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                        <label htmlFor="sponsorType" className="font-weight-bold">
                            Πρόσωπο
                        </label>
                        <select
                            name="sponsorType"
                            value={sponsorType}
                            onChange={(event) => setSponsorType(event.target.value)}
                            required
                        >
                            <option value="Νομικό">Νομικό</option>
                            <option value="Φυσικό">Φυσικό</option>
                        </select>
                    </div>
                </div>
                {sponsorType === "Νομικό"
                    && <>
                        <div className="col-12 col-md-6">
                            <div className="form-group mb-5">
                                <label htmlFor="sponsorName" className="font-weight-bold">
                                    Όνομα Οργανισμού
                                </label>
                                <input
                                    type="text"
                                    name="organization"
                                    className="form-control"
                                    id="organization"
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group mb-5">
                                <label htmlFor="sponsorName" className="font-weight-bold">
                                    Νομική Μορφή
                                </label>
                                <input
                                    type="text"
                                    name="entity"
                                    className="form-control"
                                    id="entity"
                                    required
                                />
                            </div>
                        </div>
                    </>
                }
                <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                        <label htmlFor="sponsorName" className="font-weight-bold">
                            Όνομα
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            id="firstName"
                            placeholder="π.χ Γιώργος"
                            required
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                        <label htmlFor="sponsorName" className="font-weight-bold">
                            Επίθετο
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            id="lastName"
                            placeholder="π.χ Παπαδόπουλος"
                            required
                        />
                    </div>
                </div>
                {sponsorType === "Φυσικό"
                    && <div className="col-12 col-md-6">
                        <div className="form-group mb-5">
                            <label htmlFor="sponsorName" className="font-weight-bold">
                                Ιδιότητα
                            </label>
                            <input
                                type="text"
                                name="job"
                                className="form-control"
                                id="job"
                                placeholder="π.χ Δικηγόρος"
                                required
                            />
                        </div>
                    </div>
                }
                <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                        <label htmlFor="sponsorEmail" className="font-weight-bold">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="sponsorEmail"
                            placeholder="example@example.com"
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="form-group mb-7 mb-md-9">
                        <label htmlFor="sponsorMessage" className="font-weight-bold">
                            Ενδιαφέρομαι για
                        </label>
                        <textarea
                            name="message"
                            className="form-control"
                            id="message"
                            rows="8"
                            placeholder="π.χ Ενδιαφέρομαι να γίνω χορηγός του De Jure..."
                            maxlength="3000"
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-lg-4">
                <div className="col-auto">
                    <button type="submit">
                        Επιβεβαίωση
                    </button>
                </div>
            </div>
        </form>
    );
}

export default SponsorForm;
