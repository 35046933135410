import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import FastAverageColor from 'fast-average-color';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import './Journals.css';


const JournalCard = ({ journal }) => {
    const [color, setColor] = useState('#CBCEDD');
    const [isPrivate, setIsPrivate] = useState(typeof journal.accessCode === 'undefined' ? true : journal.accessCode !== null ? true : false);
    const [isShown, setIsShown] = useState(false);
    useEffect(() => {
        loadColor();
        setIsPrivate(typeof journal.accessCode === 'undefined' ? true : journal.accessCode !== null ? true : false);
    }, [journal]);

    const loadColor = async () => {
        if (typeof journal.image !== 'undefined') {
            const fac = new FastAverageColor();
            const container = document.getElementById('container-card');
            let imgObj = new Image();
            imgObj.src = journal.image + '?' + new Date().getTime();
            imgObj.setAttribute('crossOrigin', '');
            const color = await fac.getColorAsync(imgObj).then(color => color.rgba).catch(e => console.log(e));
            setColor(color);
        }
    }

    const deleteJournal = async () => {
        if (window.confirm("Θέλετε σίγουρα να διαγράψετε αυτό το τεύχος;")) {
            try {
                await axios.delete(process.env.REACT_APP_URI + `/journals/delete/${journal._id}`);
                window.location.reload();
                history.push("/admin/journals");
            } catch (err) {
                console.log({ error: err });
            }
        }
    }

    const changePermissions = async () => {
        if (window.confirm(`Θέλετε σίγουρα να αλλάξετε την πρόσβαση αυτού του τεύχους σε ${!isPrivate ? "Ιδιωτική" : "Δημόσια"};`)) {
            try {
                await axios.put(process.env.REACT_APP_URI + `/journals/edit-access/${journal._id}`, {isPrivate: !isPrivate});
                window.location.reload();
                history.push("/admin/journals");
            } catch (err) {
                console.log({ error: err });
            }
        }
    }

    const renderCard = () => {
        return (
            <>
                <div
                    id="container-card"
                    className="card p-5 journal-card"
                    style={{background: color}}
                    onMouseEnter={() => {
                        if (localStorage.getItem('token') !== null) setIsShown(true)
                    }}
                    onMouseLeave={() => {
                        if (localStorage.getItem('token') !== null) setIsShown(false)
                    }}
                >
                    { isShown && renderHoverEffect() }
                    <div className={`align-self-center ${isShown ? 'blur' : ''}`}>
                        { journal.image ?
                            <img
                                id="img"
                                src={ journal.image }
                                alt={ journal.title }
                                className="journal-card-img"
                            />
                        :
                        <SkeletonTheme color="rgba(10, 48, 135, .25)"
                            highlightColor="rgba(10, 48, 135, .25)"
                        >
                            <Skeleton width={300} height={400} />
                        </SkeletonTheme> }
                    </div>
                </div>
                <h3 className="journal-card-text">{ journal.title || <Skeleton />}</h3>
            </>
        )
    }

    const renderHoverEffect = () => {
        if (localStorage.getItem('token') !== null) {
            return (
                <>
                    <div className="journal-card-overlay">
                        <div className="overlay-action delete-action" onClick={deleteJournal}>
                            Διαγραφή
                            <svg className="action-icon" width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.30762 6.07425L4.74992 27.6127C4.81843 28.8572 5.78839 29.7666 7.05762 29.7666H20.3268C21.6011 29.7666 22.553 28.8572 22.6345 27.6127L24.0768 6.07425" stroke="#0A3087" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M1 6.07425H26.3846" stroke="#0A3087" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                <path d="M18.8846 10.3819L18.3077 25.4588M9.07692 6.07418V3.38187C9.07626 3.16956 9.12057 2.95922 9.20731 2.76296C9.29406 2.56669 9.42152 2.38836 9.58237 2.23823C9.74322 2.08811 9.93428 1.96914 10.1446 1.88818C10.3549 1.80722 10.5802 1.76586 10.8077 1.76649H16.5769C16.8044 1.76586 17.0298 1.80722 17.24 1.88818C17.4503 1.96914 17.6414 2.08811 17.8022 2.23823C17.9631 2.38836 18.0906 2.56669 18.1773 2.76296C18.264 2.95922 18.3084 3.16956 18.3077 3.38187V6.07418H9.07692ZM13.6923 10.3819V25.4588V10.3819ZM8.5 10.3819L9.07692 25.4588L8.5 10.3819Z" stroke="#0A3087" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className="overlay-action permissions-action" onClick={changePermissions}>
                            {isPrivate ? "Ιδιωτική Πρόσβαση" : "Δημόσια Πρόσβαση"}
                            {isPrivate ?
                                <svg className="action-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.25 16.25V8.82812C26.25 7.17052 25.5915 5.58081 24.4194 4.40871C23.2473 3.23661 21.6576 2.57813 20 2.57812C18.3424 2.57813 16.7527 3.23661 15.5806 4.40871C14.4085 5.58081 13.75 7.17052 13.75 8.82812V16.25" stroke="#0A3087" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M28.75 16.25H11.25C9.17893 16.25 7.5 17.9289 7.5 20V33.75C7.5 35.8211 9.17893 37.5 11.25 37.5H28.75C30.8211 37.5 32.5 35.8211 32.5 33.75V20C32.5 17.9289 30.8211 16.25 28.75 16.25Z" stroke="#0A3087" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            :
                                <svg className="action-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.25 8.75C26.25 7.0924 25.5915 5.50269 24.4194 4.33058C23.2473 3.15848 21.6576 2.5 20 2.5C18.3424 2.5 16.7527 3.15848 15.5806 4.33058C14.4085 5.50269 13.75 7.0924 13.75 8.75V16.25" stroke="#0A3087" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M28.75 16.25H11.25C9.17893 16.25 7.5 17.9289 7.5 20V33.75C7.5 35.8211 9.17893 37.5 11.25 37.5H28.75C30.8211 37.5 32.5 35.8211 32.5 33.75V20C32.5 17.9289 30.8211 16.25 28.75 16.25Z" stroke="#0A3087" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            }
                        </div>
                        <Link to={"/journal/" + journal._id}>
                            <div className="overlay-action view-action">
                                Προβολή
                                <svg className="action-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 25C22.7614 25 25 22.7614 25 20C25 17.2386 22.7614 15 20 15C17.2386 15 15 17.2386 15 20C15 22.7614 17.2386 25 20 25Z" fill="#B5E5C8"/>
                                    <path d="M38.347 18.6406C36.2798 15.4438 33.5977 12.7281 30.5915 10.7867C27.2657 8.63672 23.5938 7.5 19.9735 7.5C16.6517 7.5 13.3852 8.44922 10.2649 10.3211C7.08291 12.2297 4.20009 15.018 1.69619 18.6078C1.41352 19.0135 1.25784 19.4941 1.24894 19.9885C1.24004 20.4829 1.37831 20.9688 1.64619 21.3844C3.70947 24.6133 6.36494 27.3328 9.32431 29.2477C12.6563 31.4062 16.2423 32.5 19.9735 32.5C23.6227 32.5 27.3024 31.3727 30.6142 29.2406C33.6188 27.3055 36.2954 24.5797 38.3548 21.3562C38.6134 20.9503 38.7502 20.4786 38.7488 19.9973C38.7474 19.5159 38.6079 19.0451 38.347 18.6406ZM20.0001 27.5C18.5167 27.5 17.0667 27.0601 15.8333 26.236C14.5999 25.4119 13.6387 24.2406 13.071 22.8701C12.5033 21.4997 12.3548 19.9917 12.6442 18.5368C12.9336 17.082 13.6479 15.7456 14.6968 14.6967C15.7457 13.6478 17.0821 12.9335 18.5369 12.6441C19.9918 12.3547 21.4998 12.5032 22.8702 13.0709C24.2407 13.6386 25.412 14.5999 26.2361 15.8332C27.0602 17.0666 27.5001 18.5166 27.5001 20C27.4978 21.9884 26.7069 23.8948 25.3009 25.3008C23.8949 26.7068 21.9885 27.4977 20.0001 27.5Z" fill="#B5E5C8"/>
                                </svg>

                            </div>
                        </Link>
                    </div>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="blur-svg">
                        <defs>
                            <filter id="blur-filter">
                                <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                            </filter>
                        </defs>
                    </svg>
                </>
            );
        }
        return null;
    }

    const renderJournalCard = () => {
        return (
            <div className="col-12 col-lg-4 mt-5">
                { isShown ?
                    renderCard()
                :
                    <Link to={"/journal/" + journal._id}>
                        { renderCard() }
                    </Link>
                }
            </div>
        );
    }

    return renderJournalCard();
}

export default JournalCard;
