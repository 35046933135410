import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import axios from "axios";
import gfm from 'remark-gfm'
import ReactMarkdown from "react-markdown";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import Navigation from '../Navigation/Navigation';
import ArticleHeader from "./ArticleHeader.js";

const Article = () => {
    let history = useHistory();
    const [article, setArticle] = useState({});
    const { slug } = useParams();

    useEffect(() => {
        getDataBySlug(slug);
    }, [slug]);

    const getDataBySlug = async (slug) => {
        try {
            const res = await axios.get(process.env.REACT_APP_URI + `/articles/${slug}`);
            setArticle(res.data);
        } catch (err) {
            console.log({ error: err });
        }
    };

    const deleteArticle = async (id) => {
        if (
        window.confirm("Θέλετε σίγουρα να διαγράψετε αυτή την ενημέρωση;")) {
            try {
                await axios.delete(process.env.REACT_APP_URI + `/articles/delete/${id}`);
                history.push("/admin/news");
            } catch (err) {
                console.log({ error: err });
            }
        }
    };

    const editDeleteArticle = (id) => {
        if (localStorage.getItem('token') !== null) {
            return (
                <div>
                    <button
                        onClick={() => deleteArticle(id)}
                        className="mr-4"
                    >
                        Διαγραφή
                    </button>
                    <button
                        onClick={() => history.push(`/admin/news/edit/${slug}`)}
                    >
                        Επεξεργασία
                    </button>
                </div>
            );
        }
    };

    const renderMarkdown = () => {
        if (article.markdown === undefined) {
            return (
                <SkeletonTheme color="rgba(10, 48, 135, .25)" highlightColor="rgba(10, 48, 135, .25)">
                    <Skeleton count={3} />
                </SkeletonTheme>
            );
        } else {
            return (
                <ReactMarkdown
                    plugins={[gfm]}
                    children={article.markdown}
                />
            );
        }
    };

    return (
        <div>
            <Navigation active="news" />
            <div className="container mb-5">
                <div className="row justify-content-center">
                    <div className="col-12 mt-4 mb-4">
                        <Link to={`${localStorage.getItem('token') !== null ? '/admin' : ''}/news`} className="back-link">
                            <svg className="mr-2" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.152 14.8281L2 7.85352L9.21755 1" stroke="#0A3087" stroke-width="2"/>
                            </svg>
                            <span className="back-link-text">Επιστροφή</span>
                        </Link>
                    </div>
                </div>
                <ArticleHeader
                    image={article.image}
                    title={article.title}
                    description={article.description}
                    createdAt={article.createdAt}
                />
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        { editDeleteArticle(article._id) }
                        <hr />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        { renderMarkdown() }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Article;
