import { Helmet } from 'react-helmet';
import Navigation from '../Navigation/Navigation';
import JournalList from './JournalList';

const Journals = () => {
    return (
        <div className="journals">
            <Helmet>
                <title>De Jure Journal | Journals</title>
                <meta name="description" content="Το De Jure αριθμεί ήδη 25 τεύχη, τα οποία είναι διαθέσιμα τόσο σε έντυπη όσο και σε ηλεκτρονική μορφή." />
            </Helmet>
            <Navigation active="journals" />
            <div className="container mt-5">
                <h1 className="text-primary bold">Τεύχη</h1>
                <JournalList />
            </div>
        </div>
    );
}

export default Journals;
