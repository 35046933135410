import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Navigation from '../Navigation/Navigation';
import ArticleList from '../../News/ArticleList';
import NewArticle from './NewArticle';
import './Articles.css';

const bearer = axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
function Articles() {
    document.title = "Admin News | De Jure";
    if (localStorage.getItem('token') === null) {
        return (<Redirect to="/admin/login"/>);
    }
    return (
        <div className="admin-articles">
            <Navigation active="news" />
            <div className="container p-5 p-lg-0 mt-5 mb-5">
                <div className="row">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        <h1 className="text-primary bold">Ενημερώσεις</h1>
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2">
                        <Link to="/admin/news/new">
                            <button>
                                Νέα Ενημέρωση
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <ArticleList />
                </div>
            </div>
        </div>
    );
}

export default Articles;
