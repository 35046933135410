import { Helmet } from 'react-helmet';
import Navigation from '../Navigation/Navigation';
import SponsorForm from './SponsorForm';

const Sponsor = () => {
    document.title = "Γίνε Χορηγός | De Jure";
    return (
        <div className="sponsor">
            <Helmet>
                <title>De Jure Journal | Γίνε Χορηγός</title>
                <meta name="description" content="Το De Jure Journal δίνει την δυνατότητα σε νέους ανθρώπους που αγαπούν την νομική επιστήμη να ασχοληθούν ουσιαστικά με τους κλάδους δικαίου που τους ενδιαφέρουν δημοσιεύοντας ποικίλα επιστημονικά άρθρα που ενισχύουν τον ακαδημαϊκό διάλογο εντός αλλά και εκτός της κοινότητας της Νομικής Σχολής Αθηνών." />
            </Helmet>
            <Navigation active="sponsor" />
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-primary bold">Γίνε Χορηγός</h1>
                        <p className="mt-lg-4">
                            Το De Jure δίνει την δυνατότητα σε νέους ανθρώπους που αγαπούν την νομική επιστήμη να ασχοληθούν ουσιαστικά με τους κλάδους δικαίου που τους ενδιαφέρουν δημοσιεύοντας επιστημονικά άρθρα που ενισχύουν τον ακαδημαϊκό διάλογο.
                        </p>
                        <p>
                            Δεδομένου ότι το De Jure παραμένει ένα εξ ολοκλήρου φοιτητικό εγχείρημα, απαραίτητη για την επίτευξη του έργου του κρίνεται η οικονομική συμβολή μέσω χορηγιών. Οι χορηγίες αυτές καλύπτουν το αναγκαίο κόστος έκδοσης, διανομής και παρουσίασης του περιοδικού στο κοινό.
                        </p>
                        <p>
                            Πέρα των ανωτέρω, οι χορηγίες προς το De Jure συνοδεύονται από πλούσια ανταποδοτικά οφέλη ανάλογα του ύψους της χορηγίας. Έτσι, εξασφαλίζεται στους χορηγούς προβολή σε ένα ευρύ κοινό νέων νομικών κι όχι μόνο.
                        </p>
                    </div>
                </div>
                <hr />
                <div className="row align-items-center mt-lg-5 mb-lg-5">
                    <div className="col-12 col-lg-8">
                        <SponsorForm />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sponsor;
