import { Link } from "react-router-dom";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import './ArticleCard.css';

const ArticleCard = ({ image, title, description, slug, createdAt, size }) => {
    const renderImg = (image) => {
        if (image === undefined) {
            return <Skeleton width={400} height={400} />;
        } else {
            return <img src={ image } alt={ title } />;
        }
    };

    const redirectLink = `${localStorage.getItem('token') !== null ? '/admin' : ''}/news/${slug}`;

    if (size === 'lg') {
      return (
            <>
                <Link to={redirectLink} className="col-12 col-lg-8 article-card">
                    <SkeletonTheme color="rgba(10, 48, 135, .25)" highlightColor="rgba(10, 48, 135, .25)">
                        { renderImg(image) }
                    </SkeletonTheme>
                </Link>
                <Link to={redirectLink} className="col-12 col-lg-4">
                    <span>
                        { moment(createdAt).format("MMMM DD YYYY") }
                    </span>
                    <h2 className="mt-3">{title || <Skeleton />}</h2>
                    <p>
                        { description || <Skeleton /> }
                    </p>
                </Link>
            </>
        );
    } else {
        return (
            <Link to={redirectLink} className={`col-12 col-lg-${size === 'md' ? '6' : '4'} article-card mt-5`}>
                <SkeletonTheme color="rgba(10, 48, 135, .25)" highlightColor="rgba(10, 48, 135, .25)">
                    { renderImg(image) }
                </SkeletonTheme>
                <span>
                    { moment(createdAt).format("MMMM DD YYYY") }
                </span>
                <h2 className="mt-3">{title || <Skeleton />}</h2>
                <p>
                    { description || <Skeleton /> }
                </p>
            </Link>
          );
    }
};

export default ArticleCard;
