import React, { useEffect, useState } from "react";
import axios from 'axios';
import ReactMarkdown from "react-markdown";
import gfm from 'remark-gfm'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const TeamMd = () => {
    const [markdown, setMarkdown] = useState();
    const [image, setImage] = useState();
    useEffect(() => {
        getTeam();
    }, []);

    const getTeam = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_URI + `/team/`);
            setMarkdown(res.data.team.markdown);
            setImage(res.data.team.image);
        } catch (err) {
            console.log({ error: err });
        }
    };

    return (
        <div className="row">
            <div className="col-12 col-lg-6 mt-5">
                { !markdown ?
                    <SkeletonTheme color="rgba(10, 48, 135, .25)" highlightColor="rgba(10, 48, 135, .25)">
                        <Skeleton count={8} />
                    </SkeletonTheme>
                :
                <ReactMarkdown
                    plugins={[gfm]}
                    children={markdown}
                />
                }
            </div>
            <div className="col-12 col-lg-6 mt-5">
                { !image ?
                    <SkeletonTheme color="rgba(10, 48, 135, .25)" highlightColor="rgba(10, 48, 135, .25)">
                        <Skeleton width={300} height={300} />
                    </SkeletonTheme>
                :
                    <img src={image} alt="Συντακτική Ομάδα" className="img-fluid" />
                }
            </div>
        </div>
    );

}

export default TeamMd;
