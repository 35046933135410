import React from "react";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import './Article.css';

const ArticleHeader = ({ image, title, description, createdAt }) => {
    const renderImg = (image) => {
        if (image === undefined) {
            return <Skeleton width={400} height={400} />;
        } else {
            return <img
                        src={ image }
                        alt={ title }
                    />;
        }
  };

  return (
        <SkeletonTheme color="rgba(10, 48, 135, .25)" highlightColor="rgba(10, 48, 135, .25)">
            <div className="article-header row justify-content-center">
                <div className="col-12 text-center">
                    { renderImg(image) }
                </div>
                <div className="col-12 col-lg-8 mt-4">
                    <span>
                        { moment(createdAt).format("MMMM DD YYYY") }
                    </span>
                    <h1 className="mt-3">{title || <Skeleton />}</h1>
                    <p className="mt-4 mb-4">
                        { description || <Skeleton /> }
                    </p>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default ArticleHeader;
