import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import Navigation from '../Navigation/Navigation';
import JournalPDF from './JournalPDF';
import AccessCodeModal from './AccessCodeModal';

import './Journals.css';

const Journal = () => {
    const [journal, setJournal] = useState({});
    const [pdf, setPDF] = useState(null);
    const { id } = useParams();
    const [accessCode, setAccessCode] = useState(null);
    const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        document.title = "Journal | De Jure";
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        loadJournal(id);
    }, [id, accessCode]);

    const loadJournal = async (id) => {
        try {
            const response = await axios.post(process.env.REACT_APP_URI + '/journals/get', {
                id: id,
                accessCode: accessCode
            });
            setShowAccessCodeModal(false);
            setJournal(response.data.journal);
            setPDF(response.data.pdf);
        } catch (error) {
            if (error && error.response && error.response.data) {
                setShowAccessCodeModal(true);
                setErrorMessage(error.response.data.accessCode === "No access code" ? '' : error.response.data.accessCode)
            } else
                console.log(error);
        }
    }

    const renderPDF = () => {
        if (pdf !== null) {
            return <JournalPDF pdf={ pdf } />
        } else {
            <Skeleton height={400} />
        }
    }

    return (
        <div className="journal mb-lg-5">
            <Navigation />
            <AccessCodeModal
                isOpen={ showAccessCodeModal }
                setShowAccessCodeModal={ setShowAccessCodeModal }
                setAccessCode={ setAccessCode }
                errorMessage={ errorMessage }
                setErrorMessage={ setErrorMessage }
            />
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 mt-4">
                        <Link to="/journals" className="back-link">
                            <svg className="mr-2" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.152 14.8281L2 7.85352L9.21755 1" stroke="#0A3087" strokeWidth="2"/>
                            </svg>
                            <span className="back-link-text">Επιστροφή</span>
                        </Link>
                        <hr />
                    </div>
                </div>
                <SkeletonTheme color="rgba(10, 48, 135, .25)" highlightColor="rgba(10, 48, 135, .25)">
                    <div className="row mt-lg-4">
                        <div className="col-12">
                            <h1 className="text-primary bold">{ journal.title || <Skeleton height={400} /> }</h1>
                            { renderPDF() }
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
        </div>
    );
}

export default Journal;
