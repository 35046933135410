import Navigation from '../Navigation/Navigation';
import { Link } from 'react-router-dom';

import './FormSubmit.css';
const FormSubmit  = () => {
    return (
        <div className="form-submit">
            <Navigation />
            <div className="row justify-content-center align-items-center mt-5 mb-5">
                <div className="col-12 col-md-8 col-lg-6">
                    <div className="card">
                        <h3>Ευχαριστούμε πολύ για το ενδιαφέρον σας στο De Jure</h3>
                        <hr />
                        <p>
                            Η ομάδα του De Jure αντιμετωπίζει τα αιτήματά σας με σοβαρότητα. Θα επικοινωνήσουμε μαζί σας μέσω εmail το συντομότερο δυνατόν.
                        </p>
                        <Link to="/">
                            <button>
                                Επιστροφή στην αρχική
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormSubmit;
