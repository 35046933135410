import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

const EditTeamForm = () => {
    const history = useHistory();
    const [markdown, setMarkdown] = useState('');
    const [id, setId] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getTeam();
    }, []);

    const getTeam = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_URI + `/team/`);
            setMarkdown(res.data.team.markdown);
            setId(res.data.team._id);
            setImage({name: "Existing Image", preview: res.data.team.image});
        } catch (err) {
            console.log({ error: err });
        }
    };

    const onDropImage = (images) => {
        if (images.length > 0) {
            let image = images[0];
            Object.assign(image, { preview: URL.createObjectURL(image) });
            setImage(image);
        }
    };

    const bytesToSize = (bytes) => {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        let formData = new FormData();
        if (image && image.name !== "Existing Image") {
            formData.append('image', image, image.name);
        } else {
            formData.append('image', image !== null ? image.preview : '');
        }
        formData.append('markdown', markdown);
        formData.append('newImage', image && image !== null && image.name !== "Existing Image");
        try {
            await axios.put(process.env.REACT_APP_URI + `/team/edit/${id}`, formData);
            history.push(`/admin/team`);
            setLoading(false);
        } catch (err) {
            console.log({ error: err });
            setLoading(false);
        }
    };

    return (
        <form className="row new-article">
            <div className="col-12 mb-4 input-item">
                <label className="font-weight-bold">Φωτογραφία</label>
                { image ?
                    <>
                        <div className="input-image-container">
                            <img src={image.preview} alt="Image preview" className="img-fluid" />
                        </div>
                        <button
                            className="mt-3"
                            onClick={() => setImage(null)}
                        >
                            Αλλαγή
                        </button>
                    </>
                    :
                    <Dropzone onDrop={ onDropImage } accept="image/*" maxFiles={ 1 }>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return (
                                <div
                                    {...getRootProps()}
                                    className={classNames('dropzone dz-clickable text-center', {'dropzone--isActive text-center': isDragActive})}
                                >
                                    <input {...getInputProps()} />
                                    { image === null ? isDragActive ?
                                        <div className="dz-default dz-message"><span>Drop files here...</span></div> :
                                        <div className="dz-default dz-message"><span>Drop an image here, or click to select files to upload.</span></div>
                                        :
                                        <div className="dz-preview dz-file-preview dz-complete text-center">
                                            <div className="dz-image">
                                                <img data-dz-thumbnail="" />
                                            </div>
                                            <div className="dz-details">
                                                <div className="dz-size">
                                                    <span data-dz-size="">{ bytesToSize(image.size) }</span>
                                                </div>
                                                <div className="dz-filename">
                                                    <span data-dz-name="">{ image.name }</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        }}
                    </Dropzone>
                }
            </div>
            <div className="col-12 mb-5 input-item markdown">
                <SimpleMDE
                    value={markdown}
                    onChange={(event) => setMarkdown(event) }
                />
            </div>
            <div className="col-12">
                <button onClick={(event) => {setLoading(true); onSubmit(event)}}>
                    { loading ? <div className="loader loader-white" /> : 'Αποθήκευση' }
                </button>
            </div>
        </form>
    );
};

export default EditTeamForm;
